.upper {
    color: white;
}

.home_container {
    max-width: 1300px;
    margin: auto;
}

.spadeList {
    list-style-type: none;
}

.spadeList > li:before {
    content: '♠️';
    font-weight: normal;
    font-size: 15pt;
    margin-left: -20px;
    margin-right: 10px;
}

.shaded_table tr:nth-child(2n) {
    background: #fcfcfc;
}

@keyframes wobbling {
    0%   { transform: perspective(800px) rotateY(-2deg) rotateZ(-4deg)}
    50%  { transform: perspective(800px) rotateY(-8deg) rotateZ(0deg)}
    100% { transform: perspective(800px) rotateY(-2deg) rotateZ(-4deg)}
}

.wobble {
    animation-name: wobbling;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    /* linear | ease | ease-in | ease-out | ease-in-out */
    animation-timing-function: ease-in-out;
}
