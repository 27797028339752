
body {
  margin: 0;
  padding: 0;
  font-family: MessinaSans, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
    overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.fr {
  display: flex;
}

.fc {
  display: flex;
  flex-direction: column;
}

.jc {
  justify-content: center;
}

.ac {
  align-items: center;
}

.clippingtext {
  background-clip: text;
  -webkit-background-clip: text;
  color: rgba(0,0,0,0.0);;
}

div > a {
  color: white;
  text-decoration: none;
}

.MuiTableCell-head {
  font-weight: bold !important;
  color: #555 !important;
}

.MuiTableCell-body {
  color: #555 !important;
}

.show_when_small {
  display: none !important;
}

#tagline {
  font-size: 3.5rem;
}

@media (max-width: 840px) {
  .hide_when_small {
    display: none !important;
  }
  .show_when_small {
    display: initial !important;
  }
}

@media (max-width: 540px) {
  .hide_when_really_small {
    display: none !important;
  }
  #tagline {
    font-size: 2.5rem;
    text-align: center;
  }

}

@media (max-width: 600px) {
  .column_when_small {
    flex-direction: column;
  }
}
