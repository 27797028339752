#phonedemo {
    /*transform: perspective(900px) rotatex(-5deg) rotatey(20deg);*/
}

.transition_scale {
    transition: transform 1s;
}

.transition_opacity {
    transition: opacity 0.5s ease-in-out;
}

.transition_width {
    transition: max-width 0.75s ease-out, opacity 0.75s ease-in-out;
}

.transition_height {
    transition: max-height 1s ease-in-out, margin 0.75s ease-in, border 0.75s ease-in, opacity 0.75s ease-in-out;
}

@keyframes spinning {
    from { transform: rotate(0deg) }
    to { transform: rotate(360deg) }
}

.spin {
    animation-name: spinning;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    /* linear | ease | ease-in | ease-out | ease-in-out */
    animation-timing-function: linear;
}